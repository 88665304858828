import { adminPermissions } from "../organization/members/permissions/permissionSamples.js";

const getUserOrganizationPermissions = (currentUser, organizationId) => {
  const memberPermissions =
    (currentUser?.organizationInfo?.organizations ?? []).find(
      (organization) => organization._id === organizationId,
    )?.memberPermissions ?? null;

  return memberPermissions?.admin ? adminPermissions : memberPermissions;
};

export default getUserOrganizationPermissions;
