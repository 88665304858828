import PropTypes from "prop-types";

import styles from "./BackDrop.module.css";

const BackDropTypes = {
  FADE: "fade",
  BLUR: "blur",
};

const BackDrop = ({ onClick, backDropType = BackDropTypes.FADE }) => {
  const blurBehindSupported = CSS.supports("backdrop-filter", "blur(10px)");
  const blurClass = blurBehindSupported
    ? styles.BackDropBlur
    : styles.BackDropFade;
  return (
    <button
      className={[
        styles.BackDrop,
        backDropType === BackDropTypes.FADE ? styles.BackDropFade : "",
        backDropType === BackDropTypes.BLUR ? blurClass : "",
      ].join(" ")}
      onClick={() => {
        onClick();
      }}
    />
  );
};

BackDrop.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default BackDrop;
