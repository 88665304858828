import styles from "./AlertMessageBox.module.css";
import PropTypes from "prop-types";

const AlertMessageBox = ({ message, isError }) => (
  <div style={{ display: "flex", justifyContent: "center" }}>
    <div
      className={styles.Alert}
      style={
        isError
          ? { backgroundColor: "#ff9696" }
          : { backgroundColor: "#20a310cf" }
      }
    >
      {isError && <span style={{ fontWeight: 500 }}>Error: </span>}
      {message}
    </div>
  </div>
);

AlertMessageBox.propTypes = {
  message: PropTypes.string,
  isError: PropTypes.bool,
  color: PropTypes.string,
};

export default AlertMessageBox;
