import { FaEllipsis, FaEllipsisVertical } from "react-icons/fa6";
import styles from "./ExpandMenuButton.module.css";
import PropTypes from "prop-types";

import classNames from "classnames";

const ExpandMenuButton = ({
  onClick,
  color,
  disabled = false,
  size = "medium",
  padding = "normal",
  fullWidth = false,
  submit = false,
  inverted = false,
  noHoverAnimation = false,
  className,
  horizontal = false,
}) => {
  const classNamesOutput = classNames(
    styles.Button,
    {
      [styles.ButtonInverted]: inverted,
      [styles.SmallSize]: size === "small",
      [styles.Fat]: padding === "fat",
      [styles.Slim]: padding === "slim",
      [styles.FullWidth]: fullWidth,
      [styles.HoverAnimation]: !noHoverAnimation,
    },
    className != null ? className : {},
  );

  const type = submit ? "submit" : "button";

  const styleObject = {};
  if (!disabled) {
    if (color) {
      if (inverted) {
        styleObject.color = color;
      } else {
        styleObject.backgroundColor = color;
      }
    }
  }

  return (
    <button
      className={classNamesOutput}
      onClick={onClick}
      disabled={disabled}
      type={type}
      style={styleObject}
    >
      {horizontal === true ? <FaEllipsis /> : <FaEllipsisVertical />}
    </button>
  );
};

export default ExpandMenuButton;

ExpandMenuButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  submit: PropTypes.bool,
  inverted: PropTypes.bool,
  required: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium"]),
  padding: PropTypes.oneOf(["normal", "fat", "slim"]),
  noHoverAnimation: PropTypes.bool,
};
