import { createPortal } from "react-dom";
import PropTypes from "prop-types";

import styles from "./PopupModal.module.css";

import UnividCard from "../generic/UnividCard";
import BackDrop from "../generic/BackDrop";
import PopupExitButton from "./PopupExitButton";

const PopupModal = ({
  children,
  handleCloseModal,
  noCard,
  disableCloseInteractions,
  wantedWidth,
  wantedHeight,
  overflowX = true,
  overflowInnerY = true,
}) => {
  const handleCloseInteraction = () => {
    if (disableCloseInteractions) {
      return;
    }

    handleCloseModal();
  };

  const dynamicOuterStyle = {
    overflowX: overflowX === false ? "hidden" : undefined,
  };

  const dynamicInnerStyle = {
    overflowY: overflowInnerY === false ? "hidden" : undefined,
    width: wantedWidth,
    height: wantedHeight,
  };

  const content = (
    <div className={styles.PopupModalContainer} style={dynamicOuterStyle}>
      <BackDrop onClick={handleCloseInteraction} />

      <div className={styles.PopupContainer}>
        {!noCard && (
          <UnividCard useDefaultWidth={false}>
            <div
              className={styles.PopupWrapper}
              style={{
                width: wantedWidth,
                height: wantedHeight,
              }}
            >
              {children}
            </div>
          </UnividCard>
        )}
        {noCard && (
          <div className={styles.PopupWrapper} style={dynamicInnerStyle}>
            {children}
          </div>
        )}

        {!disableCloseInteractions && (
          <PopupExitButton onClick={handleCloseInteraction} />
        )}
      </div>
    </div>
  );

  return createPortal(content, document.body);
};

PopupModal.propTypes = {
  children: PropTypes.node,
  handleCloseModal: PropTypes.func.isRequired,
  noCard: PropTypes.bool,
  disableCloseInteractions: PropTypes.bool,
};

export default PopupModal;
