import PropTypes from "prop-types";

const Centered = ({ children }) => (
  <div
    style={{
      position: "absolute",
      display: "grid",
      placeItems: "center",
      inset: "0",
    }}
  >
    {children}
  </div>
);

Centered.propTypes = {
  children: PropTypes.any,
};

export default Centered;
