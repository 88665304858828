import PopupModal from "../../../popups/PopupModal";
import UnividButton from "../../../buttons/UnividButton";
import CustomTextInput from "../../../textinput/CustomTextInput";
import { useDispatch } from "react-redux";
import axios from "axios";

import { getUserFetch } from "../../../../redux/actions/userActions.js";
import { useState } from "react";
import { VARIABLES } from "../../../../strings/variables.js";

const RemoveOrganizationModal = ({ organizationId, handleCloseModal }) => {
  const [deleteInput, setDeleteInput] = useState("");
  const dispatch = useDispatch();

  const deleteOrganization = async (organizationId) => {
    try {
      await axios.delete(`${VARIABLES.apiUrl}organization/${organizationId}`, {
        withCredentials: true,
      });
      dispatch(getUserFetch());
      window.location.href = "/";
    } catch (error) {
      console.log("Error deleting organization..", error);
    }
  };
  return (
    <PopupModal handleCloseModal={handleCloseModal}>
      <div>
        <h2 style={{ fontSize: "20px" }}>
          Are you sure you want to delete the organization?
        </h2>
        <p>Deleting an organization will remove all data associated with it.</p>
        <div
          style={{
            margin: "10px",
          }}
        >
          <CustomTextInput
            placeholder="Delete organization"
            value={deleteInput}
            onChange={(evt) => setDeleteInput(evt.target.value)}
          />
          Type &quot;Delete organization&quot; to confirm deletion of the
          organization.
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "right",
          }}
        >
          <UnividButton onClick={() => handleCloseModal()}>
            No, keep organization
          </UnividButton>
          <UnividButton
            color={"var(--deleteColor)"}
            disabled={
              deleteInput?.toLocaleLowerCase() !== "delete organization"
            }
            onClick={() => {
              deleteOrganization(organizationId);
            }}
          >
            Yes, delete organization
          </UnividButton>
        </div>
      </div>
    </PopupModal>
  );
};

export default RemoveOrganizationModal;
