import Centered from "../../generic/Centered";
import UnividCard from "../../generic/UnividCard";

const AccessDeniedPage = () => (
  <Centered>
    <UnividCard>
      <h1>You do not have permission to view this page</h1>
    </UnividCard>
  </Centered>
);

export default AccessDeniedPage;
