import PropTypes from "prop-types";
import styles from "./AlertMessage.module.css";

const AlertMessage = ({ message, isError, color }) => (
  <div className={styles.Alert} style={color ? { color } : {}}>
    {isError && <span style={{ fontWeight: 500 }}>Error: </span>}
    {message}
  </div>
);

AlertMessage.propTypes = {
  message: PropTypes.string,
  isError: PropTypes.bool,
  color: PropTypes.string,
};

export default AlertMessage;
