import PropTypes from "prop-types";
import { FaTimes } from "react-icons/fa";

import styles from "./PopupExitButton.module.css";

const PopupExitButton = ({ onClick }) => {
  return (
    <button className={styles.ExitButton} onClick={onClick}>
      <FaTimes />
    </button>
  );
};

PopupExitButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default PopupExitButton;
