import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const HtmlToolTip = styled(({ className, ...props }) => (
  <Tooltip
    followCursor
    {...props}
    componentsProps={{ tooltip: { className } }}
  />
))({
  backgroundColor: "white",
  color: "rgba(0, 0, 0, 0.8)",
  maxWidth: 280,
  overflow: "auto",
  border: "1px solid lightgray",
  userSelect: "none",
  fontFamily: "Lato, sans-serif",
  fontSize: "small",
});

export default HtmlToolTip;
