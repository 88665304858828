// OBS: Make sure to sync this file with the backend permissions

const defaultPermissions = {
  admin: false,
  room: {
    create: false,
    remove: true,
  },
};

const adminPermissions = {
  admin: true,
  room: {
    create: true,
    remove: true,
  },
};

export { defaultPermissions, adminPermissions };
