import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./RoundTextInput.module.css";

const RoundTextInput = ({
  value,
  id,
  type,
  name,
  placeholder,
  autoComplete = "off",
  onChange,
  valid = true,
  readOnly = false,
  required = false,
  onBlur,
  minLength,
  maxLength,
  pattern,
  fieldInfo,
  onKeyDown,
}) => {
  return (
    <div className={styles.InputContainer}>
      {fieldInfo && <div className={styles.FieldInfo}>{fieldInfo}</div>}
      <div className={classNames(styles.InputWrapper, "no-translate")}>
        <input
          id={id}
          className={classNames(styles.CustomTextInput, {
            [styles.Error]: !valid,
            [styles.ReadOnly]: readOnly,
          })}
          type={type}
          value={value}
          name={name}
          autoComplete={autoComplete}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={readOnly}
          required={required}
          minLength={minLength}
          maxLength={maxLength}
          pattern={pattern}
          placeholder={placeholder}
          onKeyDown={onKeyDown || null}
        />
      </div>
    </div>
  );
};

RoundTextInput.propTypes = {
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
};

export default RoundTextInput;
